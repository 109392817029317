//@import "~antd/dist/antd.dark.css";
//@import "~antd/dist/antd.compact.css";
@import "/ThemeVariables.less";

@path-fonts-montserrat: "../assets/fonts/Montserrat";
@path-fonts-hellix: "../assets/fonts/Hellix";

body {
  margin: 0;
  font-family: sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Matter" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// @font-face {
//   font-family: "Matter";
//   src: url("./assets/fonts/Matter/Matter-Regular.ttf");
//   src: url("./assets/fonts/Matter/Matter-Bold.ttf");
//   src: url("./assets/fonts/Matter/Matter-SemiBold.ttf");
//   src: url("./assets/fonts/Matter/Matter-Medium.ttf");
//   src: url("./assets/fonts/Matter/Matter-Light.ttf");
// }

@font-face {
  font-family: "Matter";
  src: url("@{path-fonts-montserrat}/Montserrat-Light.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Matter";
  src: url("@{path-fonts-montserrat}/Montserrat-Regular.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Matter";
  src: url("@{path-fonts-montserrat}/Montserrat-Medium.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Matter";
  src: url("@{path-fonts-montserrat}/Montserrat-SemiBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Matter";
  src: url("@{path-fonts-montserrat}/Montserrat-Bold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Hellix";
  src: url("@{path-fonts-hellix}/Hellix-Regular.ttf");
  src: url("@{path-fonts-hellix}/Hellix-Bold.ttf");
  src: url("@{path-fonts-hellix}/Hellix-SemiBold.ttf");
  src: url("@{path-fonts-hellix}/Hellix-Medium.ttf");
  src: url("@{path-fonts-hellix}/Hellix-Light.ttf");
}

@font-face {
  font-family: "Hellix-bold";
  src: url("@{path-fonts-hellix}/Hellix-Bold.ttf");
}

@font-face {
  font-family: "Matter-bold";
  src: url("@{path-fonts-montserrat}/Montserrat-Medium.ttf");
}

.ant-select-selector {
  border-radius: 0.5rem !important;
}
#menu-container .ant-menu-inline.ant-menu-root .ant-menu-item > * {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 0.875rem;
}
#menu-container .ant-menu-inline > li {
  margin: 0.5rem 0;
  border-radius: 0.5rem;
}

#menu-container .layout {
  margin-left: 300px;
  z-index: 1;
  transition: 0.2s;
}

#menu-container .layoutCollapsed {
  margin-left: 80px;
  z-index: 1;
  transition: 0.2s;
}

#menu-container .layoutCollapsed {
  margin-left: 80px;
  z-index: 1;
  transition: 0.2s;
}

#menu-container:hover {
  background-color: #f9cf6a !important;
}

.footer-menu .ant-menu-item {
  padding-left: 0.675rem !important;
}

.ant-layout-header {
  background-color: @white-color;
}

/* Dev comment */
/* .slidermenu.lightMode .ant-layout-sider-children {
  background: linear-gradient(
    180deg,
    @golden-tainoi-color -45.85%,
    @primary-color 100%
  );
} */
.companySettingTab .ant-tabs-tab {
  margin: 0 !important;
}
.companySettingTab .ant-tabs-nav-list,
.school-details .ant-tabs-nav-list {
  transform: translate(0px, 0px);
  background: @white-color;
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
}
.school-details .ant-tabs-nav-list {
  padding: 0 1rem;
  // margin: 1rem 0 0 0;
}
.school-details .ant-tabs-ink-bar {
  background: @primary-color;
}

.companySettingTab .ant-tabs-ink-bar {
  right: unset !important;
  background: @primary-color;
  left: 0 !important;
}

.companySettingTab .ant-tabs-tab-btn,
.school-details .ant-tabs-tab-btn {
  font-weight: 600 !important;
  // font-size: 1rem;
}

// .companySettingTab .ant-tabs-tab-active .ant-tabs-tab-btn,
.school-details .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: @primary-color;
}
.companySettingTab .ant-tabs-content-holder {
  border-left: none !important;
}
// .companySettingTab .ant-descriptions-item-label {
// width: 200px !important;
// }
// .companySettingTab .ant-descriptions-view {
// border-radius: 0.5rem;
// }
.companySettingTab .ant-tabs-nav-wrap,
.companySettingTab .ant-descriptions {
  padding-top: 0.875rem;
}
.companySettingTab .ant-tabs-nav {
  height: 220px;
}

// .companySettingTab .ant-modal-body {
//    padding: 1.875rem !important;
// }

.school-detail .ant-tabs-nav {
  margin: 0.875rem 0;
}

.userTab .ant-tabs-ink-bar {
  width: 0 !important;
  background: @primary-color !important;
}
.userTab .ant-tabs-nav-list {
  background: transparent;
  border-bottom: 1px solid @greyser-color;
  width: 100%;
  border-radius: 0;
}
.userTab .ant-tabs-nav::before {
  border: none;
}
.userTab .ant-tabs-tab {
  transition: 0.5s;
  border-bottom: 2px solid transparent;
  padding: 0.5rem 0 0 0;
}
.userTab .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 3px solid @primary-color;
  color: @primary-color !important;
}
.ant-typography-copy {
  color: @black-color !important;
}
.secret-key-container .ant-typography-copy {
  display: flex !important;
  align-items: center;
}
.userTab .ant-tabs-nav {
  height: auto !important;
}
.userTab .ant-tabs-nav-wrap {
  padding-top: 0;
}
.userTab .ant-tabs-nav-list {
  margin: 0 !important;
}
.school-details .suspendBtn {
  height: auto;
  width: 200px;
  background: @primary-color;
  font-weight: bold;
  border: 0;
  color: @black-color;
  font-size: 1.125rem;
  // margin-top: 1rem;
  padding: 0.5rem;
}

.suspend-company-footer {
  border-radius: 1.25rem;
}
.suspend-company-footer .ant-modal-content {
  padding: 0.625rem !important;
  border-radius: 1.25rem;
}
.editcompany .ant-drawer-header {
  background: @off-catskill-white-color;
}
.editcompany .ant-drawer-header-title {
  flex-direction: row-reverse !important;
}
.login-data .ant-input {
  height: 2.5rem;
  font-size: 1.125rem;
}
.ant-btn {
  // border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchbar {
  border-radius: 0.5rem !important;
  .ant-input::placeholder {
    color: @off-black-color;
  }
  .ant-input {
    background: @white-color;
    border: none;
    font-size: 1.125rem;
  }

  input::placeholder {
    color: @black-color;
  }
  .searchbar-search-icon {
    font-size: 1.25rem;
    color: @off-black-color;
  }
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector,
// .invitemebers .ant-select-selector,
.input-modal
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 0.5rem !important;
  height: 2.5rem;
}
.company-name {
  cursor: pointer;
  color: @primary-color;
}
.back-to-admin-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid @alto-color;
  border-radius: 0.5rem;
  padding: 0 0.625rem;
  height: 2.5rem;
  font-weight: bold;
}

.companysearchbtn {
  display: flex;

  .ant-col.ant-form-item-control {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.admin-btn .ant-btn-primary {
  border: none !important;
  background: @goldenrod-color !important;
  color: @white-color !important;
}

.disconnect-btn,
.disconnect-btn:hover,
.disconnect-btn:focus {
  color: @white-color;
  background-color: @black-color;
  border-color: @black-color;
}

.headerText {
  font-size: 1.375rem;
  text-align: center;
  font-weight: 200;
  padding-top: 1rem;
}
.billing-container {
  background: url("../assets/images/billingpageBG.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 3.125rem;
}

.site-card-wrapper {
  margin-top: 3.125rem;
}
.site-card-wrapper .ant-card {
  height: 100%;
  border-radius: 0.5rem;
  box-shadow: 0px 0.625rem 0.75rem rgba(0, 0, 0, 0.14);
}
.site-card-wrapper .ant-card-head {
  border: none;
}
.card-title {
  text-align: center;
  color: @rajah-color;
  font-size: 1rem;
  font-weight: 600;
}
.card-use-to-item {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
  letter-spacing: 0em;
  text-align: left;
  color: @woodsmoke-color;
  padding-left: 0.625rem;
}
.plan-details-text {
  display: flex;
}
.card-use-to {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.max-width {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding-bottom: 1.5rem;
  height: 77vh;
}
.otp-input input {
  width: 2.5em !important;
  height: 3.5em !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
  border: 1px solid @off-silver-color;
  margin-bottom: 0.625rem;
}

.otp .ant-form-item-control-input-content div:nth-child(1) {
  justify-content: space-around;
}
.card {
  border: 1px solid transparent;
  position: relative;
}
.card.selected {
  border: 2px solid @rajah-color;
  transform: scale(1.1);
}
.card .selectedIcon {
  position: absolute;
  right: -7px;
  top: -7px;
  z-index: 9999;
  height: 30px;
  border-radius: 100px;
}
.card.selected .selectedBtn {
  background-color: @rajah-color !important;
  border-color: @rajah-color !important;
  color: @white-color !important;
  cursor: auto;
}

#menu-container .ant-menu-item {
  height: 2.75rem;
}
.side-bar {
  color: @white-color;
  font-weight: bold;
}
#menu-container .ant-layout-sider-zero-width-trigger {
  top: 11px;
  z-index: 2;
  background: @golden-tainoi-color;
}

.company-add-account {
  display: flex !important;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 1.5rem;
}
/* .user-form {
  display: flex;
  justify-content: center;
} */
.invite-user {
  display: block;
  max-width: fit-content;
  padding-left: 0.625rem;
}
.selectedBtn:hover,
.selectedBtn:focus {
  border-color: @golden-tainoi-color !important;
  background-color: @golden-tainoi-color !important;
  color: @white-color !important;
}

.selectedBtnDark:hover,
.selectedBtnDark:focus {
  border-color: @black-color !important;
  background-color: @black-color !important;
  color: @white-color !important;
}
.ant-switch-checked.themeSwitch {
  background-color: @primary-color;
}

.basic-information .ant-descriptions-item-content {
  padding: 0 !important;
}

.basic-detail .ant-descriptions-bordered .ant-descriptions-row {
  border: 1px solid @botticelli-color;
}

.basic-detail .ant-descriptions-bordered .ant-descriptions-row,
.school-detail .ant-descriptions-item-content {
  background-color: @zircon-color;
}

.basic-detail .ant-descriptions-bordered .ant-descriptions-row,
.school-detail .ant-descriptions-item-label {
  background-color: @white-color !important;
}

.mainLayout {
  margin: 1rem;
  min-height: calc(100vh - 152px);
}

.mainLayout:has(#quick-sight) {
  height: calc(100vh - 120px);
  overflow: scroll;
}
// Dev comment :-
// .ant-table-content {
//   overflow: scroll;
// }

// .login-btn {
// padding: 0;
// height: auto;
// }

.selection-footer {
  display: flex;
  justify-content: center;
}

/* Dev comment :-
 .school-information{
  padding-top: .625rem;
} */

.overflow-wrap {
  overflow-wrap: break-word;
}

.ant-pagination-item-active {
  background: none;
  border-color: @primary-color;
}
.ant-pagination-item-active a {
  color: @primary-color;
}

.editcompany .ant-drawer-close {
  margin-right: 0px !important;
}

// .login-form .ant-btn {
//   padding: 0.125rem;
// }

.new-account-model .ant-modal-close-x,
// .company-setting-wrapper .ant-tabs-tab-btn,
.school-form-input label,
.search-school-container,
.tablecell .ant-table-column-title,
.tablecell .ant-table-cell,
.tablecell .ant-table-cell span,
.tablecell .ant-table-cell strong,
.tablecell .ant-table-cell time,
.school-form label,
.integration-tab .ant-list-item p,
.requestTab .ant-typography,
.requestTab .ant-tabs-tab-btn,
.userTab .ant-typography,
.userTab .ant-tabs-tab-btn,
.school-detail .ant-descriptions-item-label,
.school-information,
// .profile-form .ant-input,
// .profile-form strong,
// .login-form .ant-input,
// .login-form .ant-form-item-required,
.password-container .ant-form-item-label label,
.password-container .ant-input:placeholder-shown {
  font-size: 1.125rem !important;
}

.requestTab .ant-btn span,
.school-detail .ant-typography .userTab .ant-list-item-meta-description {
  font-size: 1rem !important;
}

.search-wrapper .ant-input-search-button {
  padding-top: 0.25rem !important;
}

.continue-btn span {
  display: flex;
  justify-content: center;
  line-height: 1;
}

.border-light-color {
  border-color: @light-silver;
}

.flex-col {
  display: flex !important;
  flex-direction: column !important;
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.btn-primary {
  // background-color: @primary-color !important;
  // color: #000000 !important;
  // border: 0 !important;
  // border-radius: 0.5rem !important;
  // font-weight: 600 !important;
  // font-family: "Matter" !important;
  // font-size: 1.125rem !important;
  // line-height: 1 !important;
  // padding: 0 2rem !important;
  // width: fit-content !important;
  // height: 2.5rem !important;
  // display: flex !important ;
  // align-items: center !important;
  // justify-content: center !important ;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.g-1 {
  gap: 1rem;
}

.data-card-skeleton .ant-space-item {
  width: 25% !important;
  float: left !important;
  padding: 0.625rem;
}

.data-card-skeleton .ant-space.ant-space-vertical.row {
  display: block !important;
}

.data-card-skeleton .ant-space-item span {
  height: 140px;
  width: 100%;
  border-radius: 0.625rem;
}

.skeleton-title {
  margin-bottom: 0.625rem;
}

.data-card-title .ant-skeleton-element {
  width: 100%;
}

.data-card-title .ant-space-item {
  width: 26% !important;
  float: left;
  margin-left: 5%;
  margin-right: 5%;
}

.ant-space.ant-space-vertical.d-block.clearfix.data-card-title {
  display: block;
}

.data-card-title .ant-space-item:first-child {
  margin-left: 0.625rem;
}

.data-card-title .ant-space-item:nth-child(3) {
  margin-right: 0 !important;
}

.data-card-title .ant-space-item span {
  min-width: 100% !important;
  width: 100% !important;
  height: 2.5rem !important;
}
.skeleteon-help-header {
  margin: 1rem;
  height: 250px;
  text-align: center;
}

.skeleteon-help-header {
  margin: 1rem;
  height: 220px;
  text-align: center;
}

.skeleton-help {
  width: calc(100% - 30px);
  margin: 1rem;
  padding: 0.5rem 0.625rem;
  background: @botticelli-color; //ffe
  border-radius: 0.25rem;
  margin-bottom: 0;
}

.skeleton-help span {
  width: 50% !important;
  margin: 0;
  border-radius: 0.5rem;
}

.layout-wrapper {
  max-width: 100vw;
  min-height: 100vh !important;
}

.company-logo {
  margin: 10px auto 0;
}

b,
strong {
  font-weight: 600 !important;
}

.datamanagement-item {
  // pointer-events: none;
  .ant-menu {
    // pointer-events: all;
    .configure-submenuitem {
      padding-left: 0.5rem;
      .ant-menu-submenu-title {
        padding-left: 3rem !important;
        border-left: 1px solid @primary-color;
      }
    }
  }
}

#normal_login_tAndC_help {
  line-height: 12px;
}
