.ant-modal-content {
  border-radius: 10px !important;
}
input {
  border-radius: 8px !important;
}
.secret-key-value {
  color: #959595;
}
.center_box {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-fluid {
  max-width: 100%;
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-popconfirm-buttons {
  display: flex;
}
.ant-modal-content {
  border-radius: 10px !important;
}
input {
  border-radius: 8px !important;
}
.secret-key-value {
  color: #959595;
}
.center_box {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-fluid {
  max-width: 100%;
}
.secret-key-content-container {
  font-size: 1.1rem;
}
/* inline to class css */
.pt_13 {
  padding-top: 13px;
}
.mb_5 {
  margin-bottom: 5px;
}
.mt_8 {
  margin-top: 8px;
}
.py_10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.typography-text {
  font-weight: 400;
  justify-content: center;
  font-size: 1rem;
}
.typography-title {
  font-size: 1rem;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25rem;
  text-align: center;
}
.modal-btn {
  padding: 16px 0;
  background: #ffc90a;
  border-color: #ffc90a;
  align-items: center;
  justify-content: center;
  color: "#000000";
  font-size: 14px;
  font-weight: 600;
}
.dashboard-card-header {
  font-weight: 600;
  line-height: 25px;
  padding: 10px 0;
  font-size: 18px;
}
.inviteIMG {
  height: 300px;
}
.br_20 {
  border-radius: 20px;
}
.data-card-skeleton .ant-space-item {
  width: 25% !important;
  float: left !important;
  padding: 10px;
}
.data-card-skeleton .ant-space.ant-space-vertical.row {
  display: block !important;
}
.data-card-skeleton .ant-space-item span {
  height: 140px;
  width: 100%;
  border-radius: 10px;
}
.skeleton-title {
  margin-bottom: 10px;
}
.data-card-title .ant-skeleton-element {
  width: 100%;
}
.data-card-title .ant-space-item {
  width: 26% !important;
  float: left;
  margin-left: 5%;
  margin-right: 5%;
}
.ant-space.ant-space-vertical.d-block.clearfix.data-card-title {
  display: block;
}
.data-card-title .ant-space-item:first-child {
  margin-left: 10px;
}
.data-card-title .ant-space-item:nth-child(3) {
  margin-right: 0 !important;
}
.data-card-title .ant-space-item span {
  min-width: 100% !important;
  width: 100% !important;
  height: 40px !important;
}
.skeleteon-help-header {
  margin: 15px;
  height: 250px;
  text-align: center;
}
.skeleteon-help-header {
  margin: 15px;
  height: 250px;
  text-align: center;
}
.skeleton-help {
  width: calc(100% - 30px);
  margin: 15px;
  padding: 10px;
  background: #ffe;
  border-radius: 5px;
}
.skeleton-help span {
  width: 50% !important;
  margin: 0;
  border-radius: 7px;
}
/* custom css class same as bootstrap */
.d-flex {
  display: flex;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.justify-center {
  justify-content: center;
}
.w-100 {
  width: 100%;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.flex-colum {
  flex-direction: column;
}
.text-center {
  text-align: center;
}
.text-grey {
  color: grey;
}
.clearfix::after {
  display: block;
  content: "";
  clear: both;
}
.subMenuItem .ant-menu-sub {
  background: none !important ;
}
.subMenuItem .ant-menu-submenu-title .anticon + span {
  margin-left: 1.5rem;
  font-size: 1.125rem;
}
/* mobile screen  */
@media screen and (min-width: 320px) and (max-width: 780px) {
  .ant-layout-sider-collapsed {
    flex: 0 0 0px;
  }
  .ant-layout-sider-collapsed .logo {
    height: 25px !important;
  }
  .logo {
    margin-bottom: 30px !important;
  }
  .ant-layout-sider-collapsed ul li {
    padding: 0 calc(50% - 25px / 2) !important;
    margin-bottom: 20px !important;
    display: flex;
    align-items: center;
  }
  .ant-layout-sider-collapsed ul li svg {
    display: flex;
    margin: auto;
  }
  .version {
    display: none;
  }
  .card-responsive {
    padding-bottom: 30px !important;
  }
  .billing-container {
    padding: 30px;
  }
  .profiletab .ant-tabs-nav {
    height: 175px;
  }
  .profiletab .ant-tabs-nav-list {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .organization .ant-tabs-nav-list {
    width: 100% !important;
    display: flex;
    /* padding: 10px; */
    overflow-x: scroll;
  }
  .organization .ant-tabs-nav {
    height: auto !important;
  }
  .companySettingTab .ant-tabs-ink-bar {
    background: transparent !important;
  }
  .companySettingTab .ant-tabs-ink-bar {
    right: auto !important;
    left: auto !important;
  }
  .max-width {
    height: 71vh !important;
  }
  .school-detail .ant-descriptions-bordered .ant-descriptions-row {
    display: flex;
    flex-direction: column;
  }
  .companySettingTab .ant-descriptions-item-label {
    width: 100% !important;
  }
  .refresh-icon {
    height: 25px !important;
  }
  .school-form {
    flex-direction: column;
  }
  .school-form .ant-select,
  .company-form .ant-input,
  .user-form .ant-select,
  .user-form .ant-input {
    width: 100% !important;
  }
  .companysearchbtn .ant-col.ant-form-item-control {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .companysearchbtn .ant-form-item-control-input,
  .companysearchbtn button {
    width: 100% !important;
  }
  .company-add-account {
    display: block;
    padding-bottom: 0px;
  }
  .company-add-account button,
  .user-form button {
    width: 100% !important;
  }
  .tablecell {
    overflow-x: scroll;
  }
  .user-form {
    display: block;
  }
  .mainLayout {
    min-height: 100vh;
  }
  .companySettingTab .ant-tabs-nav-wrap {
    padding-top: 0px;
  }
  .companySettingTab .ant-tabs-nav-list {
    margin: 10px 0 0 0;
    /* padding: 10px; */
    height: 2.5rem;
  }
  .integration-tab .ant-list-item {
    display: grid !important;
    grid-template-columns: 60% 40%;
  }
  .integration-tab .connect-btn {
    margin-top: 10px;
  }
  .mainLayout {
    margin: 10px;
  }
  .main-bg {
    padding: 10px !important;
  }
  .selection-footer .footer {
    position: unset !important;
  }
  .footer {
    position: absolute !important;
    bottom: 0 !important;
  }
  .school-information {
    padding-top: 0px;
  }
  .mtop-10 {
    margin-top: 10px;
  }
}
/* ipad  */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ant-layout-sider-collapsed {
    flex: 0 0 0px;
  }
  .ant-layout-sider-collapsed .logo {
    height: 25px !important;
  }
  .logo {
    margin-bottom: 30px !important;
  }
  .ant-layout-sider-collapsed ul li {
    padding: 0 calc(50% - 25px / 2) !important;
    margin-bottom: 20px !important;
    display: flex;
    align-items: center;
  }
  .ant-layout-sider-collapsed ul li svg {
    display: flex;
    margin: auto;
  }
  .version {
    display: none;
  }
  .max-width {
    height: 100% !important;
  }
  .login-bg {
    display: none;
  }
  .billing-container {
    padding: 30px;
  }
  .card-use-to-item {
    font-size: 9px !important;
  }
  .righticon {
    height: 10px !important;
  }
  .companySettingTab .ant-tabs-nav-list {
    display: flex;
    justify-content: flex-start;
  }
  .organization .ant-tabs-nav-list {
    overflow-x: none;
  }
  .school-form {
    display: flex;
    justify-content: flex-end;
  }
  .company-add-account {
    display: flex;
  }
  .user-form {
    display: flex;
    justify-content: center;
  }
  .invite-user .ant-col-md-8 {
    display: block;
    flex: 0 !important;
    max-width: fit-content !important;
  }
  .profiletab .ant-tabs-nav {
    height: 220px !important;
  }
  .integration-tab .ant-list-split {
    min-height: 40vh;
  }
  .mainLayout {
    height: auto;
  }
  .companySettingTab .ant-tabs-nav-wrap {
    padding-top: 0px;
  }
  .companySettingTab .ant-tabs-nav-wrap {
    padding: 0;
    margin: 10px 0;
    height: 2.5rem;
  }
  .companySettingTab .ant-tabs-tab {
    margin-top: 1rem;
  }
  .school-information {
    padding-top: 5px;
    margin-bottom: 0px;
  }
  .userTab .ant-tabs-tab {
    padding-bottom: 0.75rem;
  }
  .home-left-section {
    width: 100%;
  }
  .home-right-section {
    width: 100%;
    padding: 20px 10px;
  }
  .home-section-container {
    display: block;
  }
  .home-left-section .flex-row {
    overflow: hidden;
  }
  .help-search-container {
    background-size: contain;
    padding: 20px;
  }
  span.help-header-text {
    font-size: 1rem;
  }
  .home-left-section .flex-row {
    overflow: hidden;
  }
}
@media screen and (max-width: 768px) {
  .btn-primary {
    padding: 0 1rem !important;
  }
  .home-left-section {
    width: 100%;
  }
  .home-right-section {
    width: 100%;
  }
  .home-section-container {
    flex-direction: column !important ;
  }
  .home-container {
    background-size: 180px;
    background-position: right bottom;
    height: auto;
    padding: 25px;
  }
  .home-col-2 img {
    height: auto;
    width: auto !important;
    max-width: 140px;
    max-height: 120px;
    object-fit: contain;
    transform: translateX(10px);
  }
  .home-col-2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  h1.ant-typography.home-title {
    font-size: 1.125rem !important;
  }
  .flex-col.home-container span {
    font-size: 1rem;
  }
  .home-left-title {
    font-size: 1rem !important;
    margin: 0 0 0.5rem 0 !important;
    padding: 0 !important ;
  }
  .home-left-section button.ant-btn.btn-primary {
    font-size: 1rem !important;
  }
  .home-col-1.flex-col {
    justify-content: center;
  }
  .home-left-section .flex-row {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .home-right-section.flex-center {
    padding: 20px 5px;
  }
  .home-left-section .flex-row {
    overflow: hidden;
  }
  .help-search-container {
    background-size: contain;
    padding: 20px;
  }
  span.help-header-text {
    font-size: 1rem;
  }
  .home-left-section .flex-row {
    overflow: hidden;
  }
  .data-source-box .title-wrapper {
    display: block;
  }
  .data-source-box .datasource-container-col-1 {
    width: 100%;
  }
  .data-source-box .datasource-container-col-2 {
    width: 100%;
    padding-top: 0;
    padding-left: 20px;
  }
  .data-source-box .ant-collapse-expand-icon {
    position: absolute;
    right: 30px;
    transform: scale(1.5);
    top: 10px;
  }
  .data-source-box .datasource-container-col-2 > div {
    width: 55% !important;
  }
  .data-source-box .datasource-container-col-2 button {
    margin-top: -5px;
  }
  .invite-container {
    text-align: center;
    display: block;
    padding: 15px !important;
  }
  .invite-container img {
    width: 50%;
  }
  .invite-text-container {
    height: auto !important;
  }
  .invite-text-container button.ant-btn.ant-btn-default.btn-primary {
    white-space: normal;
    padding: 5px 10px;
    height: auto;
    margin-top: 10px;
  }
}
@media screen and (max-width: 600px) {
  .school-tab-box .ant-tabs-tab > div {
    margin-left: 0 !important;
    width: 100%;
    text-align: center;
  }
  .school-tab-box .ant-tabs-nav-list {
    padding: 0 10px !important;
  }
  .school-tab-box .ant-tabs-tab .ant-tabs-tab-btn {
    white-space: normal;
    text-align: center;
    border-left: crimson;
  }
  .school-tab-box .ant-tabs-tab {
    margin-left: 20px !important;
  }
  .school-tab-box .ant-tabs-nav-list > .ant-tabs-tab:first-child {
    margin-left: 0 !important;
  }
  .secret-key-header-container {
    display: block !important;
  }
  .secret-key-header-container .secret-key-header {
    display: block;
  }
  .school-description-container .ant-descriptions-item-label,
  .school-description-container .ant-descriptions-item-content {
    padding: 5px 10px;
    font-size: 1rem !important;
  }
}
@media only screen and (max-width: 576px) {
  .data-source-box .datasource-container-col-2 {
    display: block;
    text-align: center;
  }
  .data-source-box .datasource-container-col-2 button {
    margin-top: 15px !important;
    margin-bottom: 5px;
  }
  .data-source-box .datasource-container-col-2 > div {
    width: 100% !important;
  }
  .home-col-2 img {
    max-width: 100px;
    height: 80px;
  }
  .pricing-bullet-points {
    padding: 10px !important;
    border-radius: 10px !important;
    margin-top: 1rem;
  }
  .schoolbi-plan-heading {
    font-size: 25px !important;
  }
  .invite-user {
    padding-left: 0 !important;
  }
  .inviteIMG {
    height: 150px !important;
  }
  .user-search-container {
    margin-top: 10px !important;
  }
  .file-icon {
    margin-right: 10px;
  }
  .file-icon {
    height: 25px;
    margin-top: 5px;
    margin-right: 2px !important ;
  }
  .top-up-btn-wrapper {
    margin-left: 0px;
  }
  .data-source-clicker {
    padding: 0px !important;
  }
  .d-none {
    display: none !important;
  }
  .search-container {
    margin-bottom: 8px;
  }
  .search-container,
  .search-container .ant-space,
  .header-wrapper .ant-input-wrapper {
    width: 100% !important;
  }
  .search-wrapper .ant-item:nth-child(2) {
    width: 100%;
  }
  .search-view-container {
    display: flex;
  }
  /* .search-container .ant-input-wrapper{
        width: 0;
    } */
  .search-wrapper .ant-space-align-center .ant-space-item:nth-child(1) {
    width: 100%;
  }
  .search-container .ant-space-item:nth-child(1) {
    width: 100%;
    margin-left: 10px;
  }
  .school-form-input {
    margin-right: 0;
  }
  .profiletab .ant-tabs-nav {
    height: 50px !important;
  }
  .companySettingTab .ant-tabs-nav {
    margin: 0 !important;
    padding: 0 !important;
  }
  .search-school-container {
    width: 100%;
  }
  .company-setting-wrapper .ant-tabs-nav-list {
    padding: 10px;
    gap: 1rem;
  }
  .userTab .ant-tabs-tab {
    padding-bottom: 0.75rem;
  }
  .userTab .ant-tabs-nav-list {
    padding-bottom: 0;
    padding-left: 0;
  }
  .search-school-container {
    width: 100%;
  }
  .home-container {
    background-size: 140px;
    height: auto;
    padding: 15px;
  }
  .home-left-section .flex-row {
    padding: 15px;
  }
  .invite-container img {
    width: 75%;
  }
  .help-search-container {
    height: 150px;
  }
  .help-search-container .help-search-title {
    font-size: 1.25rem;
  }
  .help-search-container .help-search-subtitle {
    font-size: 1rem;
  }
}
@media screen and (max-width: 420px) {
  .school-tab-box .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 14px !important;
  }
  .school-tab-box .ant-tabs-tab {
    margin-left: 10px !important;
  }
  .school-tab-box .ant-tabs-nav-list > .ant-tabs-tab:first-child {
    margin-left: -6px !important;
  }
  .secret-key-header-container button.ant-btn.ant-btn-default.btn-primary {
    max-width: 100%;
    white-space: normal;
    height: auto !important;
    padding: 10px 10px;
  }
  .secret-key-content-container {
    display: block !important;
    text-align: center;
  }
  .secret-key-content-container span {
    display: block;
    font-size: 1rem;
    margin: 10px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .pricing-bullet-points {
    padding: 10px !important;
    border-radius: 10px !important;
    margin-top: 1rem;
  }
  .schoolbi-plan-heading {
    font-size: 25px !important;
  }
  .file-icon {
    margin-right: 10px;
  }
  .top-up-btn-wrapper {
    margin-left: 50px;
  }
  .data-source-clicker {
    padding: 0px !important;
  }
  .userTab .ant-tabs-tab {
    padding-bottom: 0.75rem;
  }
  .search-school-container {
    width: 100% !important ;
  }
  .school-form-input {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 825px) {
  .file-icon {
    height: 25px;
    margin-top: 5px;
    margin-right: 2px !important ;
  }
  .selectedScreen {
    padding: 30px 0;
  }
  .top-up-btn-wrapper {
    margin-left: 50px;
  }
  .data-source-clicker {
    padding: 0px !important;
  }
  .data-source-clicker span {
    font-size: 12px !important;
  }
}
@media only screen and (min-width: 825px) and (max-width: 992px) {
  .selectedScreen {
    padding: 30px 0;
  }
  .file-icon {
    height: 25px;
    margin-top: 5px;
    margin-right: 2px !important ;
  }
  .data-source-clicker {
    padding: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .file-icon {
    height: 25px;
    margin-top: 5px;
    margin-right: 3px !important;
  }
  .selectedScreen {
    padding: 30px 0;
  }
  .top-up-btn-wrapper {
    margin-left: 35px;
  }
  .data-source-clicker {
    padding: 0px !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .file-icon {
    height: 25px;
    margin-top: 5px;
    margin-right: 3px !important;
  }
  .top-up-btn-wrapper {
    margin-left: 25px;
  }
  .data-source-clicker {
    padding: 0px !important;
  }
  .home-left-section .flex-row {
    overflow: hidden;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .file-icon {
    height: 30px;
    margin-top: 5px;
  }
  .top-up-btn-wrapper {
    margin-left: 35px;
  }
  .data-source-clicker {
    padding: 0px !important;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 2400px) {
  .top-up-btn-wrapper {
    margin-left: 50px;
  }
  .data-source-clicker {
    padding: 0px !important;
  }
}
@media only screen and (min-width: 2400px) {
  .top-up-btn-wrapper {
    margin-left: 50px;
  }
  .data-source-clicker {
    padding: 0px !important;
  }
}
@media screen and (max-width: 992px) {
  #menu-container .layout {
    z-index: 1;
    transition: 0.2s;
  }
  .search-school-container {
    width: 100%;
  }
  .companysearchbtn .ant-form-item-control-input,
  .companysearchbtn button {
    width: 100% !important;
  }
  .back-to-admin-link {
    display: none !important;
  }
}
@media screen and (max-width: 786px) {
  .allplan-container {
    flex-direction: column;
    border: none;
  }
  .currentplaninfo-container {
    width: 100%;
  }
  .process-container {
    text-align: end;
  }
  .help-md-container .ant-drawer-content-wrapper {
    width: 100% !important ;
  }
}
body {
  margin: 0;
  font-family: sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Matter" !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@font-face {
  font-family: "Matter";
  src: url("../assets/fonts/Montserrat/Montserrat-Light.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Matter";
  src: url("../assets/fonts/Montserrat/Montserrat-Regular.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Matter";
  src: url("../assets/fonts/Montserrat/Montserrat-Medium.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Matter";
  src: url("../assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Matter";
  src: url("../assets/fonts/Montserrat/Montserrat-Bold.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Hellix";
  src: url("../assets/fonts/Hellix/Hellix-Regular.ttf");
  src: url("../assets/fonts/Hellix/Hellix-Bold.ttf");
  src: url("../assets/fonts/Hellix/Hellix-SemiBold.ttf");
  src: url("../assets/fonts/Hellix/Hellix-Medium.ttf");
  src: url("../assets/fonts/Hellix/Hellix-Light.ttf");
}
@font-face {
  font-family: "Hellix-bold";
  src: url("../assets/fonts/Hellix/Hellix-Bold.ttf");
}
@font-face {
  font-family: "Matter-bold";
  src: url("../assets/fonts/Montserrat/Montserrat-Medium.ttf");
}
.ant-select-selector {
  border-radius: 0.5rem !important;
}
#menu-container .ant-menu-inline.ant-menu-root .ant-menu-item > * {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 0.875rem;
}
#menu-container .ant-menu-inline > li {
  margin: 0.5rem 0;
  border-radius: 0.5rem;
}
#menu-container .layout {
  margin-left: 300px;
  z-index: 1;
  transition: 0.2s;
}
#menu-container .layoutCollapsed {
  margin-left: 80px;
  z-index: 1;
  transition: 0.2s;
}
#menu-container .layoutCollapsed {
  margin-left: 80px;
  z-index: 1;
  transition: 0.2s;
}
#menu-container:hover {
  background-color: #f9cf6a !important;
}
.footer-menu .ant-menu-item {
  padding-left: 0.675rem !important;
}
.ant-layout-header {
  background-color: #ffffff;
}
/* Dev comment */
/* .slidermenu.lightMode .ant-layout-sider-children {
  background: linear-gradient(
    180deg,
    @golden-tainoi-color -45.85%,
    @primary-color 100%
  );
} */
.companySettingTab .ant-tabs-tab {
  margin: 0 !important;
}
.companySettingTab .ant-tabs-nav-list,
.school-details .ant-tabs-nav-list {
  transform: translate(0px, 0px);
  background: #ffffff;
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
}
.school-details .ant-tabs-nav-list {
  padding: 0 1rem;
}
.school-details .ant-tabs-ink-bar {
  background: #ffc90a;
}
.companySettingTab .ant-tabs-ink-bar {
  right: unset !important;
  background: #ffc90a;
  left: 0 !important;
}
.companySettingTab .ant-tabs-tab-btn,
.school-details .ant-tabs-tab-btn {
  font-weight: 600 !important;
}
.school-details .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffc90a;
}
.companySettingTab .ant-tabs-content-holder {
  border-left: none !important;
}
.companySettingTab .ant-tabs-nav-wrap,
.companySettingTab .ant-descriptions {
  padding-top: 0.875rem;
}
.companySettingTab .ant-tabs-nav {
  height: 220px;
}
.school-detail .ant-tabs-nav {
  margin: 0.875rem 0;
}
.userTab .ant-tabs-ink-bar {
  width: 0 !important;
  background: #ffc90a !important;
}
.userTab .ant-tabs-nav-list {
  background: transparent;
  border-bottom: 1px solid #d2dce3;
  width: 100%;
  border-radius: 0;
}
.userTab .ant-tabs-nav::before {
  border: none;
}
.userTab .ant-tabs-tab {
  transition: 0.5s;
  border-bottom: 2px solid transparent;
  padding: 0.5rem 0 0 0;
}
.userTab .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 3px solid #ffc90a;
  color: #ffc90a !important;
}
.ant-typography-copy {
  color: #000000 !important;
}
.secret-key-container .ant-typography-copy {
  display: flex !important;
  align-items: center;
}
.userTab .ant-tabs-nav {
  height: auto !important;
}
.userTab .ant-tabs-nav-wrap {
  padding-top: 0;
}
.userTab .ant-tabs-nav-list {
  margin: 0 !important;
}
.school-details .suspendBtn {
  height: auto;
  width: 200px;
  background: #ffc90a;
  font-weight: bold;
  border: 0;
  color: #000000;
  font-size: 1.125rem;
  padding: 0.5rem;
}
.suspend-company-footer {
  border-radius: 1.25rem;
}
.suspend-company-footer .ant-modal-content {
  padding: 0.625rem !important;
  border-radius: 1.25rem;
}
.editcompany .ant-drawer-header {
  background: #f1f5f9;
}
.editcompany .ant-drawer-header-title {
  flex-direction: row-reverse !important;
}
.login-data .ant-input {
  height: 2.5rem;
  font-size: 1.125rem;
}
.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchbar {
  border-radius: 0.5rem !important;
}
.searchbar .ant-input::placeholder {
  color: #00000073;
}
.searchbar .ant-input {
  background: #ffffff;
  border: none;
  font-size: 1.125rem;
}
.searchbar input::placeholder {
  color: #000000;
}
.searchbar .searchbar-search-icon {
  font-size: 1.25rem;
  color: #00000073;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
.input-modal .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0.5rem !important;
  height: 2.5rem;
}
.company-name {
  cursor: pointer;
  color: #ffc90a;
}
.back-to-admin-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 0.5rem;
  padding: 0 0.625rem;
  height: 2.5rem;
  font-weight: bold;
}
.companysearchbtn {
  display: flex;
}
.companysearchbtn .ant-col.ant-form-item-control {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.admin-btn .ant-btn-primary {
  border: none !important;
  background: #f9cf6a !important;
  color: #ffffff !important;
}
.disconnect-btn,
.disconnect-btn:hover,
.disconnect-btn:focus {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.headerText {
  font-size: 1.375rem;
  text-align: center;
  font-weight: 200;
  padding-top: 1rem;
}
.billing-container {
  background: url("../assets/images/billingpageBG.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 3.125rem;
}
.site-card-wrapper {
  margin-top: 3.125rem;
}
.site-card-wrapper .ant-card {
  height: 100%;
  border-radius: 0.5rem;
  box-shadow: 0px 0.625rem 0.75rem rgba(0, 0, 0, 0.14);
}
.site-card-wrapper .ant-card-head {
  border: none;
}
.card-title {
  text-align: center;
  color: #f6bd6c;
  font-size: 1rem;
  font-weight: 600;
}
.card-use-to-item {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
  letter-spacing: 0em;
  text-align: left;
  color: #161918;
  padding-left: 0.625rem;
}
.plan-details-text {
  display: flex;
}
.card-use-to {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.max-width {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding-bottom: 1.5rem;
  height: 77vh;
}
.otp-input input {
  width: 2.5em !important;
  height: 3.5em !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
  border: 1px solid #cbcbcb;
  margin-bottom: 0.625rem;
}
.otp .ant-form-item-control-input-content div:nth-child(1) {
  justify-content: space-around;
}
.card {
  border: 1px solid transparent;
  position: relative;
}
.card.selected {
  border: 2px solid #f6bd6c;
  transform: scale(1.1);
}
.card .selectedIcon {
  position: absolute;
  right: -7px;
  top: -7px;
  z-index: 9999;
  height: 30px;
  border-radius: 100px;
}
.card.selected .selectedBtn {
  background-color: #f6bd6c !important;
  border-color: #f6bd6c !important;
  color: #ffffff !important;
  cursor: auto;
}
#menu-container .ant-menu-item {
  height: 2.75rem;
}
.side-bar {
  color: #ffffff;
  font-weight: bold;
}
#menu-container .ant-layout-sider-zero-width-trigger {
  top: 11px;
  z-index: 2;
  background: #ffce58;
}
.company-add-account {
  display: flex !important;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 1.5rem;
}
/* .user-form {
  display: flex;
  justify-content: center;
} */
.invite-user {
  display: block;
  max-width: fit-content;
  padding-left: 0.625rem;
}
.selectedBtn:hover,
.selectedBtn:focus {
  border-color: #ffce58 !important;
  background-color: #ffce58 !important;
  color: #ffffff !important;
}
.selectedBtnDark:hover,
.selectedBtnDark:focus {
  border-color: #000000 !important;
  background-color: #000000 !important;
  color: #ffffff !important;
}
.ant-switch-checked.themeSwitch {
  background-color: #ffc90a;
}
.basic-information .ant-descriptions-item-content {
  padding: 0 !important;
}
.basic-detail .ant-descriptions-bordered .ant-descriptions-row {
  border: 1px solid #d3dfea;
}
.basic-detail .ant-descriptions-bordered .ant-descriptions-row,
.school-detail .ant-descriptions-item-content {
  background-color: #fafcff;
}
.basic-detail .ant-descriptions-bordered .ant-descriptions-row,
.school-detail .ant-descriptions-item-label {
  background-color: #ffffff !important;
}
.mainLayout {
  margin: 1rem;
  min-height: calc(100vh - 152px);
}
.mainLayout:has(#quick-sight) {
  height: calc(100vh - 120px);
  overflow: scroll;
}
.selection-footer {
  display: flex;
  justify-content: center;
}
/* Dev comment :-
 .school-information{
  padding-top: .625rem;
} */
.overflow-wrap {
  overflow-wrap: break-word;
}
.ant-pagination-item-active {
  background: none;
  border-color: #ffc90a;
}
.ant-pagination-item-active a {
  color: #ffc90a;
}
.editcompany .ant-drawer-close {
  margin-right: 0px !important;
}
.new-account-model .ant-modal-close-x,
.school-form-input label,
.search-school-container,
.tablecell .ant-table-column-title,
.tablecell .ant-table-cell,
.tablecell .ant-table-cell span,
.tablecell .ant-table-cell strong,
.tablecell .ant-table-cell time,
.school-form label,
.integration-tab .ant-list-item p,
.requestTab .ant-typography,
.requestTab .ant-tabs-tab-btn,
.userTab .ant-typography,
.userTab .ant-tabs-tab-btn,
.school-detail .ant-descriptions-item-label,
.school-information,
.password-container .ant-form-item-label label,
.password-container .ant-input:placeholder-shown {
  font-size: 1.125rem !important;
}
.requestTab .ant-btn span,
.school-detail .ant-typography .userTab .ant-list-item-meta-description {
  font-size: 1rem !important;
}
.search-wrapper .ant-input-search-button {
  padding-top: 0.25rem !important;
}
.continue-btn span {
  display: flex;
  justify-content: center;
  line-height: 1;
}
.border-light-color {
  border-color: #bababa;
}
.flex-col {
  display: flex !important;
  flex-direction: column !important;
}
.flex-row {
  display: flex !important;
  flex-direction: row !important;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.w-100 {
  width: 100%;
}
.g-1 {
  gap: 1rem;
}
.data-card-skeleton .ant-space-item {
  width: 25% !important;
  float: left !important;
  padding: 0.625rem;
}
.data-card-skeleton .ant-space.ant-space-vertical.row {
  display: block !important;
}
.data-card-skeleton .ant-space-item span {
  height: 140px;
  width: 100%;
  border-radius: 0.625rem;
}
.skeleton-title {
  margin-bottom: 0.625rem;
}
.data-card-title .ant-skeleton-element {
  width: 100%;
}
.data-card-title .ant-space-item {
  width: 26% !important;
  float: left;
  margin-left: 5%;
  margin-right: 5%;
}
.ant-space.ant-space-vertical.d-block.clearfix.data-card-title {
  display: block;
}
.data-card-title .ant-space-item:first-child {
  margin-left: 0.625rem;
}
.data-card-title .ant-space-item:nth-child(3) {
  margin-right: 0 !important;
}
.data-card-title .ant-space-item span {
  min-width: 100% !important;
  width: 100% !important;
  height: 2.5rem !important;
}
.skeleteon-help-header {
  margin: 1rem;
  height: 250px;
  text-align: center;
}
.skeleteon-help-header {
  margin: 1rem;
  height: 220px;
  text-align: center;
}
.skeleton-help {
  width: calc(100% - 30px);
  margin: 1rem;
  padding: 0.5rem 0.625rem;
  background: #d3dfea;
  border-radius: 0.25rem;
  margin-bottom: 0;
}
.skeleton-help span {
  width: 50% !important;
  margin: 0;
  border-radius: 0.5rem;
}
.layout-wrapper {
  max-width: 100vw;
  min-height: 100vh !important;
}
.company-logo {
  margin: 10px auto 0;
}
b,
strong {
  font-weight: 600 !important;
}
.datamanagement-item .ant-menu .configure-submenuitem {
  padding-left: 0.5rem;
}
.datamanagement-item .ant-menu .configure-submenuitem .ant-menu-submenu-title {
  padding-left: 3rem !important;
  border-left: 1px solid #ffc90a;
}
#normal_login_tAndC_help {
  line-height: 12px;
}
.fs-12 {
  font-size: 0.75rem !important;
}
.fs-14 {
  font-size: 0.875rem !important;
}
.fs-16 {
  font-size: 1rem !important;
}
.fs-18 {
  font-size: 1.125rem !important;
}
.fs-20 {
  font-size: 1.25rem !important;
}
.fs-22 {
  font-size: 1.375rem !important;
}
.fs-24 {
  font-size: 1.5rem !important;
}
.font-bold {
  font-weight: 600 !important ;
}
.color-white {
  color: #ffffff !important;
}
.color-black {
  color: #000000 !important;
}
.color-light-black {
  color: #333 !important;
}
.color-red {
  color: #ff4d4f;
}
.primary-color {
  color: #ffc90a !important;
}
.secondary-color {
  color: #c5c5c5;
}
.tertiary-color {
  color: #64748b;
}
.silver-color {
  color: #c5c5c5;
}
.grey-color {
  color: grey;
}
.light-grey-color {
  color: #8C8C8C;
}
.blackish-grey-color {
  color: #4a4a4a;
}
.off-mine-shaft-color {
  color: #303030;
}
.dove-color {
  color: #6d6d6d;
}
.outer-space-color {
  color: #363e44 !important;
}
.danger-color {
  color: #ed4b4b;
}
.mine-shaft-color {
  color: #252525;
}
.bg-silver {
  background-color: #c5c5c5;
}
.bg-black {
  background-color: #000000;
}
.bg-white {
  background-color: #ffffff;
}
.bg-off-catskill-color {
  background-color: #f1f5f9;
}
.bg-light-yellow {
  background-color: #fff9e5;
}
.s {
  background-color: #ffc90a;
}
.primary-bottom-border {
  border-bottom: 1px solid #ffc90a;
}
.mt-8 {
  margin-top: 0.5rem;
}
.mt-16 {
  margin-top: 1rem;
}
.mt-18 {
  margin-top: 1.125rem;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.mb-10 {
  margin-bottom: 0.675rem;
}
.mb-12 {
  margin-bottom: 0.75rem;
}
.m-0 {
  margin: 0;
}
.ps-8 {
  padding-left: 0.5rem;
}
.pt-10 {
  padding-top: 0.675rem;
}
.px-2 {
  padding: 0 2rem;
}
.p-2 {
  padding: 2rem;
}
.pb-30 {
  padding-bottom: 1.875rem;
}
.z-1 {
  z-index: 999 !important;
}
.common-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.bg-none {
  background: none !important;
}
a {
  text-decoration: none !important;
}
p {
  margin: 0;
}
.refresh-icon {
  color: #00000073;
  transform: scale(-1, 1);
}
.divider {
  border-top: 1px solid #d2dce3;
}
.cursor-pointer {
  cursor: pointer !important;
  pointer-events: all !important;
}
.cursor-none {
  cursor: none;
  pointer-events: none;
}
.logo {
  height: 3rem;
  margin: 0;
  padding: 0.5rem 0;
}
.logo-container {
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}
.secondary-btn {
  font-size: 1.125rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
  color: #000000;
  border: 1px solid #000000;
  background: transparent;
  height: 2.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.black-btn {
  color: #000000 !important;
  border: 1px solid #000000 !important;
  background-color: transparent !important;
}
.black-bg-btn {
  color: #ffffff !important;
  background-color: #000000 !important;
}
.manage-billing-btn {
  background-color: #000000 !important;
  color: #ffffff !important;
}
.manage-billing-btn:disabled {
  color: #000000 !important;
}
.w-50 {
  width: 50%;
}
.w-40 {
  width: 40%;
}
.w-80 {
  width: 80% !important;
}
.w-175 {
  width: 175px;
}
.w-100px {
  width: 100px !important ;
}
.min100vh {
  min-height: 100vh;
}
.modal-close-btn {
  color: #000000;
  transform: scale(1.5) translate(-2px, -5px);
  position: absolute;
  right: -50%;
  bottom: 50%;
  font-size: 1rem;
}
.modal-style {
  border-radius: 1.25rem;
}
.profile-bg {
  padding: 1rem;
  border-radius: 0.5rem;
}
.line-height-1 {
  line-height: 1;
}
.rotate-45 {
  transform: rotate(45deg);
}
.rotate-90 {
  transform: rotate(90deg);
}
.gap-05 {
  gap: 0.5rem;
}
.font-matter-bold {
  font-family: "Matter-bold";
}
.font-weight-500 {
  font-weight: 500 !important;
}
.loading {
  position: fixed;
  z-index: 1000;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}
/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}
/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;
  border: 2px solid #ffc90a;
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}
/* Animation */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.ant-popover-message-title {
  padding: 0;
}
.tooltip-container svg {
  width: 1.5rem;
  height: 1.5rem;
}
.ant-popover {
  z-index: 10300;
}
.anticon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-message-custom-content {
  display: flex;
}
.ant-select {
  display: flex;
  align-items: center;
}
.ant-modal-close-x {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-modal-close-x .anticon-close {
  z-index: -1;
}
.ant-modal .ant-picker-clear {
  background: none !important;
}
.invitemebers .ant-select-selector {
  width: 100%;
}
.invitemebers .ant-select-selector .ant-select-selection-item-remove {
  display: flex;
}
.mh-250px {
  min-height: 15.625rem;
}
.mh-400px {
  min-height: 25rem;
}
.h-300px {
  height: 300px;
}
.ant-table table {
  table-layout: fixed;
}
.pe-none {
  pointer-events: none !important;
}
.api-result-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.api-result-modal .ant-image {
  display: flex;
  align-self: center;
}
.ant-popover-buttons {
  display: flex;
  justify-content: end;
}
.text-align-end {
  text-align: end;
}
.lh-16 {
  line-height: 16px;
}
.opacity-0 {
  opacity: 0;
}
.success-btn {
  background-color: #16a34a;
  color: #ffffff;
  border-color: #16a34a;
}
.color-yellow {
  color: #dead00;
}
.select-container .ant-select-selector {
  width: 100% !important;
}
.dataset-icon {
  height: 1.25rem;
  width: 1.25rem;
}
.border-none {
  border: none;
}
.rule-engine-del-icon {
  border-color: #363e44;
  border-radius: 50%;
}
.rule-engine-del-icon:hover {
  border-color: #ed4b4b;
  color: #ed4b4b !important;
}
.outer-space-btn,
.outer-space-btn:hover {
  background-color: #363e44 !important;
  border-color: #363e44 !important;
  color: #ffffff !important;
}
.veracross-modal .ant-image {
  display: flex;
  margin: 0 auto;
}
.submitBtn {
  margin: 0;
}
.collapse-navbar {
  border-radius: 0.5rem;
  position: absolute;
  right: -1rem;
  top: 1rem;
}
.sortable-list-tab {
  z-index: 9999;
}
.sortable-list-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.vertical-sider-menu .ant-menu-submenu-title {
  display: flex;
}
.vertical-sider-menu .vertical-subMenuItem {
  border: 1px solid #ffffff;
  border-radius: 0.5rem;
  background: #000;
  margin: 0 1rem;
}
.vertical-sider-menu .ant-menu-vertical {
  border-radius: 0.5rem;
  margin: 0 0.25rem;
}
.sider {
  position: fixed;
  z-index: 99;
  height: 100vh;
}
.collapsed-logo-container {
  display: flex;
  border-bottom: 2px solid #ffffff;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.collapsed-logo-container .logo {
  height: unset !important;
}
.padding-675 {
  padding-left: 0.675rem;
}
.report-builder-menu {
  border-right: 0 !important;
}
.report-builder-menu .ant-menu-submenu-arrow {
  color: #ffffff;
}
.report-builder-menu .anticon {
  display: flex !important;
}
.main-menu {
  padding: 0 1rem;
}
.filter-effect {
  filter: blur(4px);
}
.recent-files-radio-container span {
  font-weight: 600;
}
.highlight-search {
  background-color: #ffc90a;
  font-weight: bolder;
  color: #ffffff !important;
}
.text-justify {
  text-align: justify;
}
.success-color {
  color: #16a34a;
}
.warning-color {
  color: #ed4b4b;
}
:root {
  ---header-font: 28px;
  ---sub-header-font: 22px;
  ---p-font: 14px;
  ---button-font: 16px;
  ---table-font: 14px;
  ---tag-font: 13px;
  ---select-font: 14px;
}
@media screen and (min-width: 300px) and (max-width: 780px) {
  :root {
    ---header-font: 18px;
    ---sub-header-font: 16px;
    ---p-font: 14px;
    ---button-font: 14px;
    ---table-font: 12px;
    ---tag-font: 10px;
    ---select-font: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  :root {
    ---header-font: 18px;
    ---sub-header-font: 16px;
    ---p-font: 14px;
    ---button-font: 14px;
    ---table-font: 12px;
    ---tag-font: 13px;
    ---select-font: 14px;
  }
}
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  :root {
    ---header-font: 35px;
    ---sub-header-font: 24px;
    ---p-font: 22px;
    ---button-font: 20px;
    ---table-font: 18px;
    ---tag-font: 16px;
    ---select-font: 15px;
  }
}
.header-font {
  font-size: var(---header-font) !important;
  font-weight: 900 !important;
}
.sub-header-font {
  font-size: var(---sub-header-font) !important;
  font-weight: 700 !important;
}
.p-font {
  font-size: var(---p-font) !important;
}
.button-font {
  font-size: var(---button-font) !important;
}
.table-font {
  font-size: var(---table-font) !important;
}
.tag-font {
  font-size: var(---tag-font) !important;
}
.select-font .ant-select-selection-item {
  font-size: var(---select-font) !important;
}
.search-font input {
  font-size: var(---select-font) !important;
}
