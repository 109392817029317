//** Theme Variables imports */
@import "/ThemeVariables.less";

//**************************************************************************************
//******************************** Font Styles Start *********************************//
//**************************************************************************************

.fs-12 {
  font-size: 0.75rem !important;
}

.fs-14 {
  font-size: 0.875rem !important;
}

//** Font Size 16 pixel*/
.fs-16 {
  font-size: 1rem !important;
}

//** Font Size 18 pixel*/
.fs-18 {
  font-size: 1.125rem !important;
}

//** Font Size 20 pixel*/
.fs-20 {
  font-size: 1.25rem !important ;
}

//** Font Size 22 pixel*/
.fs-22 {
  font-size: 1.375rem !important ;
}

//** Font Size 24 pixel*/
.fs-24 {
  font-size: 1.5rem !important ;
}

//** Font Bold */
.font-bold {
  font-weight: 600 !important ;
}

//**************************************************************************************
//******************************** Font Styles End ***********************************//
//**************************************************************************************
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//**************************************************************************************
//********************** Color & Background Color Styles Start ***********************//
//**************************************************************************************

//** Color white */
.color-white {
  color: @white-color !important;
}

//** Color Black */
.color-black {
  color: @black-color !important;
}

.color-light-black {
  color: @light-black-color !important;
}

//** Color red */
.color-red {
  color: @error-color;
}

//** Primary Color */
.primary-color {
  color: @primary-color !important;
}

//** Secondary Color */
.secondary-color {
  color: @silver-color;
}

//** Tertiary Color */
.tertiary-color {
  color: @tertiary-color;
}

//** Primary Color */
.silver-color {
  color: @silver-color;
}

//** Grey Color */
.grey-color {
  color: @grey-color;
}

.light-grey-color {
  color: @light-grey-color;
}

//** Blackish Grey Color */
.blackish-grey-color {
  color: @tundora-color;
}

//** Mine Shaft Color */
.off-mine-shaft-color {
  color: @off-mine-shaft-color;
}

//** Dove Grey Color */
.dove-color {
  color: @light-dove-gray-color;
}

//** Outer Space Color */
.outer-space-color {
  color: @outer-space-color !important ;
}

//** Danger Red Color */
.danger-color {
  color: @red-circle-color;
}

.mine-shaft-color {
  color: @mine-shaft-color;
}

//** */
.bg-silver {
  background-color: @silver-color;
}

.bg-black {
  background-color: @black-color;
}

.bg-white {
  background-color: @white-color;
}

.bg-off-catskill-color {
  background-color: @off-catskill-white-color;
}

.bg-light-yellow {
  background-color: @early-down-color;
}

.s {
  background-color: @primary-color;
}

.primary-bottom-border {
  border-bottom: 1px solid @primary-color;
}

//**************************************************************************************
//*********************** Color & Background Color Styles End *************************//
//**************************************************************************************
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//**************************************************************************************
//************************* Margin and Padding Styles Start ***************************//
//**************************************************************************************
// Dev comment
// .mb-0{

// }

//** Margin top 8 pixel */
.mt-8 {
  margin-top: 0.5rem;
}

//** Margin top 16 pixel */
.mt-16 {
  margin-top: 1rem;
}
//** Margin top 18 pixel */
.mt-18 {
  margin-top: 1.125rem;
}

//** Margin bottom 8 pixel */
.mb-8 {
  margin-bottom: 0.5rem;
}

//** Margin bottom 10 pixel */
.mb-10 {
  margin-bottom: 0.675rem;
}

//** Margin bottom 12 pixel */
.mb-12 {
  margin-bottom: 0.75rem;
}

//** All margin 0
.m-0 {
  margin: 0;
}

//** Padding left 8 pixel */
.ps-8 {
  padding-left: 0.5rem;
}

.pt-10 {
  padding-top: 0.675rem;
}

.px-2 {
  padding: 0 2rem;
}
.p-2 {
  padding: 2rem;
}

.pb-30 {
  padding-bottom: 1.875rem;
}

.z-1 {
  z-index: 999 !important;
}

//**************************************************************************************
//************************* Margin and Padding Styles End ***************************//
//**************************************************************************************
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//**************************************************************************************
//*************************** Miscellaneous Styling Start *****************************//
//**************************************************************************************

//** Common Background Properties */
.common-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

//** Background None */
.bg-none {
  background: none !important;
}

//** Remove Underline */
a {
  text-decoration: none !important;
}
//** Remove Margin  */
p {
  margin: 0;
}

.refresh-icon {
  color: @off-black-color;
  transform: scale(-1, 1);
}

.divider {
  border-top: 1px solid @greyser-color;
}
.cursor-pointer {
  cursor: pointer !important;
  pointer-events: all !important;
}
.cursor-none {
  cursor: none;
  pointer-events: none;
}
//**************************************************************************************
//*************************** Miscellaneous Styling End ******************************//
//**************************************************************************************
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//**************************************************************************************
//****************************** Logo Styling Start **********************************//
//**************************************************************************************
.logo {
  height: 3rem;
  margin: 0;
  padding: 0.5rem 0;
}

.logo-container {
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}
//**************************************************************************************
//******************************* Logo Styling End ***********************************//
//**************************************************************************************

.secondary-btn {
  font-size: 1.125rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
  color: @black-color;
  border: 1px solid @black-color;
  background: transparent;
  height: 2.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.black-btn {
  color: @black-color !important ;
  border: 1px solid @black-color !important;
  background-color: transparent !important;
}
.black-bg-btn {
  color: @white-color !important;
  background-color: @black-color !important;
}
.manage-billing-btn {
  background-color: @black-color !important ;
  color: @white-color !important;
}
.manage-billing-btn:disabled {
  color: @black-color !important;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-80 {
  width: 80% !important;
}

.w-175 {
  width: 175px;
}

.w-100px {
  width: 100px !important ;
}

.min100vh {
  min-height: 100vh;
}

.modal-close-btn {
  color: @black-color;
  transform: scale(1.5) translate(-2px, -5px);
  position: absolute;
  right: -50%;
  bottom: 50%;
  font-size: 1rem;
}

.modal-style {
  border-radius: 1.25rem;
}

.profile-bg {
  padding: 1rem;
  border-radius: 0.5rem;
}

.line-height-1 {
  line-height: 1;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}
//**************************************************************************************
//**************************** FlexBox Styling Start **********************************//
//**************************************************************************************
.gap-05 {
  gap: 0.5rem;
}
//**************************************************************************************
//**************************** FlexBox Styling End ***********************************//
//**************************************************************************************

.font-matter-bold {
  font-family: "Matter-bold";
}

.font-weight-500 {
  font-weight: 500 !important;
}

//**************************************************************************************
//**************************** Loading Style Start ***********************************//
//**************************************************************************************

.loading {
  position: fixed;
  z-index: 1000;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 2px solid @primary-color;
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

//**************************************************************************************
//**************************** Loading Style End ***********************************//
//**************************************************************************************

.ant-popover-message-title {
  padding: 0;
}

.tooltip-container {
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.ant-popover {
  z-index: 10300;
}

.anticon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-message-custom-content {
  display: flex;
}

.ant-select {
  display: flex;
  align-items: center;
}

.ant-modal-close-x {
  display: flex;
  align-items: center;
  justify-content: center;

  .anticon-close {
    z-index: -1;
  }
}

.ant-modal {
  .ant-picker-clear {
    background: none !important;
  }
}

.invitemebers {
  .ant-select-selector {
    width: 100%;
    .ant-select-selection-item-remove {
      display: flex;
    }
  }
}

.mh-250px {
  min-height: 15.625rem;
}
.mh-400px {
  min-height: 25rem;
}

.h-300px {
  height: 300px;
}

.ant-table table {
  table-layout: fixed;
}

.pe-none {
  pointer-events: none !important;
}

.api-result-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .ant-image {
    display: flex;
    align-self: center;
  }
}

.ant-popover-buttons {
  display: flex;
  justify-content: end;
}

.text-align-end {
  text-align: end;
}

.lh-16 {
  line-height: 16px;
}

.opacity-0 {
  opacity: 0;
}

.success-btn {
  background-color: @green-circle-color;
  color: @white-color;
  border-color: @green-circle-color;
}

.color-yellow {
  color: rgba(222, 173, 0, 1);
}

.select-container {
  .ant-select-selector {
    width: 100% !important;
  }
}
.dataset-icon {
  height: 1.25rem;
  width: 1.25rem;
}

.border-none {
  border: none;
}

.rule-engine-del-icon {
  border-color: @outer-space-color;
  border-radius: 50%;
}

.rule-engine-del-icon:hover {
  border-color: @red-circle-color;
  color: @red-circle-color !important;
}

.outer-space-btn,
.outer-space-btn:hover {
  background-color: @outer-space-color !important;
  border-color: @outer-space-color !important;
  color: @white-color !important;
}

.veracross-modal {
  .ant-image {
    display: flex;
    margin: 0 auto;
  }
}

.submitBtn {
  margin: 0;
}

.collapse-navbar {
  border-radius: 0.5rem;
  position: absolute;
  // left: -1.5rem;
  right: -1rem;
  top: 1rem;
}

.sortable-list-tab {
  z-index: 9999;
}

.sortable-list-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.vertical-sider-menu {
  .ant-menu-submenu-title {
    // background-color: red;
    display: flex;
  }
  .vertical-subMenuItem {
    border: 1px solid @white-color;
    border-radius: 0.5rem;
    background: #000;
    margin: 0 1rem;
  }

  .ant-menu-vertical {
    // background-color: @white-color !important;
    // border: 1px solid @white-color;
    border-radius: 0.5rem;
    margin: 0 0.25rem;
  }
}

.sider {
  position: fixed;
  z-index: 99;
  height: 100vh;
}

.collapsed-logo-container {
  display: flex;
  border-bottom: 2px solid @white-color;
  width: 100%;
  align-items: center;
  justify-content: center;

  .logo {
    height: unset !important;
  }
}

.padding-675 {
  padding-left: 0.675rem;
}

.report-builder-menu {
  border-right: 0 !important;
  .ant-menu-submenu-arrow {
    color: @white-color;
  }
  .anticon {
    display: flex !important;
  }
}

.main-menu {
  padding: 0 1rem;
}

.filter-effect {
  filter: blur(4px);
}

.recent-files-radio-container {
  span {
    font-weight: 600;
  }
}

.highlight-search {
  background-color: @primary-color;
  font-weight: bolder;
  color: @white-color !important;
}

.text-justify {
  text-align: justify;
}

.success-color {
  color: @green-circle-color;
}

.warning-color {
  color: @red-circle-color;
}
